.iconpack {
    font-size: 16px;
}

.iconpack > * {
    margin: 2px 3px
}

td {
    vertical-align: middle;
}

.storageGroup {
    width: 60%;
    font-size: 16px;
    margin: auto;
    padding: 5px;
}

.storageGroup > * {
    margin: 2px 3px
}

.actionTip {
    font-size: 10px;
}

.responsiveChart {
    margin: auto;
}
