.smallText {
    font-size: 10px;
    font-weight: normal;
}
.saveText {
    padding: 0;
}

.nameLabel {
    margin: 0 5px;
}
.storeRow > * {
    margin: 0 5px;
}

.newItemGroup > * {
   margin: 0 5px;
    vertical-align: middle;
}
.itemName {
    padding-right: 4px;
}

td {
    vertical-align: middle;
    padding-left: 1px;
    padding-right: 1px;
}
