.clickable:hover {
    color: cornflowerblue;
}

.dangerous {
    color: #500000;
}
.dangerous:hover {
    color: crimson;
}

.disabled-clickable {
    color: lightgrey;
}
